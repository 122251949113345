import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-modalaviso',
  templateUrl: './modalaviso.component.html',
  styleUrls: ['./modalaviso.component.css']
})
export class ModalavisoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalavisoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  ngOnInit(): void {
  }

  cancel = () => this.dialogRef.close(null);

  public static Open(matDialogInstance: MatDialog, data: any, cb: Function): MatDialogRef<ModalavisoComponent> {
    let modalDialog = matDialogInstance.open(ModalavisoComponent, {
      disableClose: false,
      id: "modalaviso-modal-component",
      height: "10px",
      width: "150px",
      data: data
    });

    modalDialog.afterClosed().subscribe(res => cb(res));
    return modalDialog;
  }

}
