import { Component, OnInit, ChangeDetectorRef, Inject, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { OpentokService } from '../opentok.service';
import { DOCUMENT } from '@angular/common';
import { faCompress } from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import * as OT from '@opentok/client';

@Component({
  selector: 'app-root',
  templateUrl: './videoapp.component.html',
  styleUrls: ['./videoapp.component.css'],
  providers: [ OpentokService ]
})
export class VideoAppComponent implements OnInit {
  view: 'loading'|'streaming'|'watch'|'failed' = 'loading';
  doPublish = false;
  session: OT.Session;
  streams: {strm: OT.Stream, data:any, role:string}[] = [];
  connections: {conn: OT.Connection, name: string}[] = [];
  elem;
  fullscreenOn = false;
  faCompress = faCompress;
  faExpand = faExpand;
  screen:boolean = false;

  get currentState () {
    return (<any>this.session).currentState;
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  constructor(@Inject(DOCUMENT) private document: any, public translate: TranslateService, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, public ots: OpentokService)
  {
    // Set spanish as default language
    translate.setDefaultLang('es');

    // Set navigator lang
    translate.use(navigator.language.split("-")[0]);
   }


  ngOnInit () {
    this.elem = document.documentElement;
    this.view = 'loading';
    let sess = this.route.snapshot.params.sess||null;
    if (sess) {
      this.ots.initSession(sess).then((session: OT.Session) => {
        this.doPublish = this.ots.userConfig.AutostartPublisher;

        if (this.ots.userConfig.MustSendLocation) {
          navigator.geolocation.getCurrentPosition(p=> this.ots.submitLocationData({
            Lat: p.coords.latitude,
            Long: p.coords.longitude,
            Accuracy: p.coords.accuracy
          }), err => console.error('Error while fetching location', err));
        }

        this.handleNameModifications(() => {
          this.session = session;
          this.session.on('streamCreated', (event) => {
            let data = {
              strm: event.stream,
              data: JSON.parse(event.stream.connection.data),
              role: 'unspecified'
            };
            if (data.data.Role) data.role = data.data.Role;

            if (data.strm.videoType === 'screen'){
              this.screen = true;
            }

            this.streams.push(data);
            this.ots.playAudio('connected');
            this.changeDetectorRef.detectChanges();
          });
          this.session.on('streamDestroyed', (event) => {
            let idx = -1;
            this.streams.forEach(st => {
              if (st.strm == event.stream)
                idx = this.streams.indexOf(st);

                if (st.strm.videoType === 'screen'){
                  this.screen = false;
                }
            })
            if (idx > -1) {
              this.streams.splice(idx, 1);
              this.changeDetectorRef.detectChanges();
              this.ots.playAudio('disconnected');
            }
          });
          this.session.on('signal', (event) => {
            if (event.type.endsWith('newConnection')) {
              this.connections.push({conn:event.from, name: JSON.parse(event.data).userName});
              console.log(this.connections);
            }
          });
          this.session.on('connectionCreated', (event) => {
            this.session.signal({
              to: event.connection,
              type: 'newConnection',
              data: JSON.stringify({
                userName: this.ots.sessionData.Session.UserData.name,
                role: this.ots.sessionData.Session.Permissions.TokboxRole
              })
            }, err=>err&&console.error(err));
          });
          this.session.on('connectionDestroyed', (event) => {
            let conn;
            this.connections.forEach(item => {if (event.connection==item.conn) conn = item});
            const idx = this.connections.indexOf(conn);
            if (idx > -1) this.connections.splice(idx, 1);
            console.log(this.connections);
          });

          this.session.on("sessionDisconnected", function(event){
            // TODO: Handle message
            //alert("The session disconnected. " + event.reason);
            //alert("La sesión no se pudo realizar debido a una mala calidad de conexión");
            //alert(JSON.stringify(event));
            if(event.reason == 'networkDisconnected') alert('Se ha perdido la conexión debido a la baja calidad en la red, por favor, intente conectarse de nuevo')
          });
        });
      })
      .then(() => this.ots.connect().then((test)=> {
        console.warn(this.ots.sessionData);

      }))
      .catch((err) => {
        this.view = 'failed';
        console.error(err);
        alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
      });
    } else {
      this.view = 'failed';
      alert('Session not provided (participantHash).');
    }
  }

  handleNameModifications(cb: Function) {
    if (this.ots.userConfig.MustChangeName) {
      let name = prompt("Por favor introduzca su nombre", "Invitado");
      this.ots.sessionData.Session.UserData.name = name;
    }
    if (cb) cb();
  }

  getSpectatorNames(separator=", ",lastseparator=" y ") {
    let spectators = [];
    this.connections.forEach(conn => {
      let found = false;
      this.streams.forEach(stream => {
        if (stream.strm.connection==conn.conn) found = true;
      });
      if (!found && conn.conn != this.session.connection) spectators.push(conn.name);
    });

    if (spectators.length!=0) {
      let last = spectators[spectators.length-1];
      spectators.splice(spectators.length-1,1);

      return (spectators.length)?spectators.join(separator)+lastseparator+last:last;
    } else return ''
  }

  startArchive = () => this.ots.startArchive();
  stopArchive = () => this.ots.stopArchive();

  toggleFullscreen(){

    if(this.checkFullscreen()) this.closeFullscreen();
    else this.openFullscreen();

  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  checkFullscreen(){

    if(!this.document.fullscreenElement && !this.document.mozFullScreenElement && !this.document.webkitFullscreenElement && !this.document.msFullscreenElement) return false;
    else return true;

  }

}
